<template lang="">
  <div>
    <b-card>
      <basetable
        :dataurl="dataurl"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
        :title_modal="title_modal"
        :is-modal="isModal"
      />
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTableV4.vue'
import {BCard} from 'bootstrap-vue'

export default {
  components: {
    basetable,
    BCard
  },
  data() {
    return {
      title:'Daftar '+this.$route.meta.pageTitle,
      title_modal: this.$route.meta.pageTitle,
      dataurl:'/issue-type',
      baseroute:'operasional-issue-type',
      isModal:true,
      fields:[
        { key: 'name', label: 'Judul Isu/Kerusakan', type:'input', rules:'required', placeholder:'Judul Isu/Kerusakan', sortable: true},
      ],
    }
  },
}
</script>